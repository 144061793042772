import React, { useCallback, useState } from 'react';
import { Grid, Menu, Popover } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import { useRouter } from 'next/router';
import pathToRegexp from 'path-to-regexp';
import { GlobalOutlined, LogoutOutlined, MenuOutlined, ProfileOutlined } from '@ant-design/icons';
import { localeSelector } from 'src/selector/app';
import { currentEmployeeSelector, currentUserSelector } from 'src/selector/user';
import { logout } from 'src/actions/user';
import { modalMethods } from '../POSModal';
import { LOCALE_EN, VERIFY_BY_EMAIL, VERIFY_BY_SMS } from 'src/consts';
import { IDENTITY_ADMIN } from 'src/consts/user';
import { isGroupUserSelector } from 'src/selector/app';
import { activatedSelector } from 'src/selector/setting';
import { KEYS, getSessionItem } from 'src/store/storage';
import { getResetOptions } from 'src/services/passwords';
import { languageIcons, languageLabels, locales } from './const';
import { switchLocale } from 'src/actions/app';
import { PageRestaurantActivate, PageRoleEdit } from 'src/consts/routes';
import { ResetPasswordModal } from '../ResetPassword';
import styles from './index.less';

const { useBreakpoint } = Grid;
const KEY_ROLE = 'role';
const KEY_LOGOUT = 'logout';
const KEY_RESET_LOGIN_PASSWORD = 'reset_login_password';
const KEY_GOTO_ACTIVATE = 'goto_actiavte';
const resetPasswordImgPath = `${process.env.ASSETS_PREFIX}/assets/reset_password.svg`;

const GlobalHeaderRight = () => {
  const screens = useBreakpoint();
  const locale = useSelector(localeSelector);
  const dispatch = useDispatch();
  const router = useRouter();
  const { email, identity } = useSelector(currentUserSelector);
  const currentEmployee = useSelector(currentEmployeeSelector);
  const activated = useSelector(activatedSelector);
  const isGroup = useSelector(isGroupUserSelector);
  const [verifyMeans, setVerifyMeans] = useState(() => []);
  const [resetPasswordModalVisible, setResetPasswordModalVisible] = useState(false);
  const [isChecking, setIsChecking] = useState(false);

  const profileMenu = {
    label: email,
    key: 'profile',
    children: [
      {
        key: KEY_LOGOUT,
        icon: <LogoutOutlined />,
        label: <FormattedMessage id="component.user.logout" />,
      },
      {
        type: 'divider',
      },
    ],
  };

  if (isGroup) {
    profileMenu.children.push({
      key: KEY_RESET_LOGIN_PASSWORD,
      icon: <img src={resetPasswordImgPath} width={16} className="nav-icon" />,
      label: <FormattedMessage id="component.reset_login_password" />,
    });
  } else {
    if (!activated) {
      profileMenu.children.push({
        label: <FormattedMessage id="component.user.unactivated" />,
        key: KEY_GOTO_ACTIVATE,
      });
    } else {
      profileMenu.children.push({
        key: KEY_RESET_LOGIN_PASSWORD,
        icon: <img src={resetPasswordImgPath} width={16} className="nav-icon" />,
        label: <FormattedMessage id="component.reset_login_password" />,
      });

      let roleName;
      if (identity === IDENTITY_ADMIN) {
        roleName = 'AM';
      } else if (currentEmployee) {
        roleName = locale === LOCALE_EN ? currentEmployee.role_name : currentEmployee.role_foreign_name;
      }

      if (roleName) {
        profileMenu.children.push({
          icon: <ProfileOutlined />,
          label: roleName,
          key: KEY_ROLE,
        });
      }
    }
  }

  const menus = [
    profileMenu,
    {
      label: 'language/语言',
      key: 'language',
      icon: <GlobalOutlined />,
      children: locales.map((lang) => ({
        key: lang,
        label: <span style={{ marginLeft: 6 }}>{languageLabels[lang]}</span>,
        icon: languageIcons[lang],
      })),
    },
  ];

  const onReset = useCallback(async () => {
    setResetPasswordModalVisible(true);

    if (verifyMeans.length) {
      return;
    }

    const userEmail = getSessionItem(KEYS.email);
    let response;
    setIsChecking(true);

    try {
      response = await getResetOptions(userEmail);
    } catch (e) {
      response = { success: false };
    }

    setIsChecking(false);

    if (!response.success) {
      setVerifyMeans([VERIFY_BY_EMAIL]);
      return;
    }

    const means = [];
    const { email, sms } = response.data;

    if (email) means.push(VERIFY_BY_EMAIL);
    if (sms) means.push(VERIFY_BY_SMS);
    setVerifyMeans(means);
  }, [verifyMeans]);

  const gotoRoleEditPage = useCallback(() => {
    if (!currentEmployee || !currentEmployee.role_id) return;
    if (pathToRegexp(PageRoleEdit).test(router.pathname)) return;
    router.push({
      pathname: PageRoleEdit.replace(':id', currentEmployee.role_id),
      query: {
        from: router.asPath,
      },
    });
  }, [currentEmployee]);

  const onMenuClick = (event) => {
    const { key } = event;

    switch (key) {
      case KEY_LOGOUT: {
        modalMethods
          .confirm({
            messageId: 'logout-prompt',
            type: 'info',
          })
          .then(() => {
            dispatch(logout());
          });
        return;
      }

      case KEY_RESET_LOGIN_PASSWORD: {
        onReset();
        return;
      }

      case KEY_ROLE: {
        gotoRoleEditPage();
        return;
      }

      case KEY_GOTO_ACTIVATE: {
        router.push({
          router: PageRestaurantActivate,
          query: {
            from: router.asPath,
          },
        });
        return;
      }
    }

    if (locales.includes(key)) {
      dispatch(switchLocale(key));
    }
  };

  const menuProps = {
    items: menus,
    onClick: onMenuClick,
    selectedKeys: [locale],
    getPopupContainer: (triggerNode) => triggerNode.parentNode,
  };

  if (screens.lg) {
    menuProps.mode = 'horizontal';
  } else {
    menuProps.mode = 'inline';
    menuProps.defaultOpenKeys = ['profile', 'language'];
  }

  let element;

  if (screens.lg) {
    element = <Menu {...menuProps} />;
  } else {
    element = (
      <Popover
        arrowPointAtCenter
        trigger={['click']}
        placement="bottom"
        getPopupContainer={(triggerNode) => triggerNode.parentNode}
        content={<Menu {...menuProps} />}
      >
        <span className={styles.trigger}>
          <MenuOutlined />
        </span>
      </Popover>
    );
  }

  return (
    <div className={styles.right}>
      {element}
      {resetPasswordModalVisible && (
        <ResetPasswordModal
          locale={locale}
          open={resetPasswordModalVisible}
          onCancel={() => setResetPasswordModalVisible(false)}
          verifyMeans={verifyMeans}
          email={email}
          isChecking={isChecking}
        />
      )}
    </div>
  );
};

export default GlobalHeaderRight;
