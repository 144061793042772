import React, { Fragment } from 'react';
import { Result, Button } from 'antd';
import { FormattedMessage } from 'react-intl';
import * as Sentry from '@sentry/react';
import { useRouter } from 'next/router';

const ErrorDisplay = ({ error, componentStack, resetError }) => {
  const router = useRouter();
  const isHome = ['/', '/dashboard'].includes(router.pathname);

  const goHome = () => {
    router.push('/');
  };

  return (
    <Result
      status="error"
      title="Page Error"
      subTitle={<FormattedMessage id="pageError.subtitle" />}
      extra={
        <Fragment>
          <div>You have encountered an error</div>
          <div>{error.toString()}</div>
          <div>{componentStack}</div>
          {!isHome && (
            <Button type="primary" size="large" onClick={goHome} style={{ marginRight: 20 }}>
              <FormattedMessage id="action.backHome" />
            </Button>
          )}
          <Button type="primary" onClick={resetError}>
            <FormattedMessage id="action.refresh" />
          </Button>
        </Fragment>
      }
    />
  );
};

const ErrorBoundary = ({ children }) => <Sentry.ErrorBoundary fallback={ErrorDisplay}>{children}</Sentry.ErrorBoundary>;

export default ErrorBoundary;
