export const locales = ['en', 'zh'];

export const languageLabels = {
  zh: '简体中文',
  en: 'English',
};

export const languageIcons = {
  zh: '🇨🇳',
  en: '🇺🇸',
};
