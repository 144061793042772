import { createSelector } from 'reselect';

export const restauantProfileSelector = createSelector(
  (state) => state.setting.isLoadingRestaurantProfile,
  (state) => state.setting.isLoadRestaurantProfileDone,
  (state) => state.setting.settings,
  (state) => state.setting.restaurantInfo,
  (isLoading, isLoadDone, settings, restaurantInfo) => ({
    isLoading,
    isLoadDone,
    settings,
    restaurantInfo,
  })
);

export const restaurantPaymentChannelCode = createSelector(
  (state) => state.setting.isLoadRestaurantProfileDone,
  (state) => state.setting.restaurantInfo,
  (isLoadDone, restaurantInfo) => ({
    payment_channel_code: isLoadDone ? restaurantInfo.payment_channel_code || '' : undefined,
  })
);

export const onlinePlatformsSelector = createSelector(
  (state) => state.setting.onlinePlatforms,
  (onlinePlatforms) => onlinePlatforms
);

export const employeeWorkflowSettingsSelector = createSelector(
  (state) => state.setting.isLoadingEmployeeWorkflowSettings,
  (state) => state.setting.employeeWorkflowSettings,
  (isLoading, settings) => ({ isLoading, settings })
);

export const isUpdatingEmployeeWorkflowSettingsSelector = createSelector(
  (state) => state.setting.isUpdatingEmployeeWorkflowSettings,
  (isUpdating) => isUpdating
);

export const activatedSelector = createSelector(
  (state) => state.setting.activated,
  (activated) => activated
);

export const onlineOrderingSettingsSelector = createSelector(
  (state) => state.setting.isLoadingOnlineOrderingSettings,
  (state) => state.setting.isLoadOnlineOrderingSettingsFailed,
  (state) => state.setting.onlineOrderingSettings,
  (isLoading, isLoadFailed, onlineOrderingSettings) => ({
    isLoading,
    isLoadFailed,
    onlineOrderingSettings,
  })
);

export const isUpdatingOnlineOrderingSettingsSelector = createSelector(
  (state) => state.setting.isUpdatingOnlineOrderingSettings,
  (isUpdating) => isUpdating
);
