import React, { useRef, useEffect } from 'react';

function useClickOutside(ref, onClickOutside) {
  useEffect(() => {
    function handleClickOutSide(event) {
      event.stopPropagation();

      if (ref.current && !ref.current.contains(event.target)) {
        typeof onClickOutside === 'function' && onClickOutside();
      }
    }

    document.addEventListener('click', handleClickOutSide);
    return () => {
      document.removeEventListener('click', handleClickOutSide);
    };
  }, [ref, onClickOutside]);
}

/***
 * @param {Object} props
 * @param {Function} props.onClickOutside 点击外部区域的huid
 * @param {Boolean} props.inline 是否是行内元素
 */
export default function ClickOutsideHandlerWrapper(props) {
  const { onClickOutside, inline } = props;
  const wrapperRef = useRef(null);
  useClickOutside(wrapperRef, onClickOutside);

  return (
    <div ref={wrapperRef} style={{ display: inline ? 'inline-block' : 'block' }}>
      {props.children}
    </div>
  );
}
