import React, { useEffect, useMemo, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { FormattedMessage, useIntl } from 'react-intl';
import { Button, Empty, Input, Tree, Spin } from 'antd';
import { BankOutlined, ClusterOutlined, DownOutlined } from '@ant-design/icons';
import debounce from 'lodash/debounce';
import { localeSelector, restaurantListSelector } from '../../selector/app';
import ClickOutsideHandlerWrapper from '../../hooks/useClickOutside';
import { setCurrentPermissions, setCurrentRgId } from '../../actions/rgUser';
import { getItemName } from '../../utils/utils';
import { generateGroupTree } from '../../utils/tree';
import { currentUserSelector } from 'src/selector/user';
import { currentGroupInfoSelector } from 'src/selector/rgUser';
import styles from './index.less';

const { Search } = Input;
const defaultWidth = 420;

const GroupsDropdown = () => {
  const ref = useRef();
  const dispatch = useDispatch();
  const { formatMessage } = useIntl();
  const locale = useSelector(localeSelector);
  const { currentGroupInfo } = useSelector(currentGroupInfoSelector);
  const { currentRgId } = useSelector(currentUserSelector);
  const { isLoading, isLoadSuccess, restaurantList } = useSelector(restaurantListSelector);
  const [open, setOpen] = useState(false);
  const [searchValue, setSearchValue] = useState('');
  const [panelPosition, setPanelPosition] = useState(() => ({ left: 0, width: defaultWidth }));

  function handeResize() {
    const element = ref.current;
    if (!element) return;
    const box = element.getBoundingClientRect();
    if (!box) return;
    const { left, width } = box;
    const windowWidth = window.innerWidth;
    if (left + width < windowWidth) return;
    let _width = width;
    let _left = windowWidth - width;
    if (_left < 0) {
      _left = 0;
      _width = windowWidth;
    }
    setPanelPosition({ left: _left, width: _width });
  }

  const resizeHandler = debounce(handeResize, 250);

  useEffect(() => {
    window.addEventListener('resize', resizeHandler);

    return () => {
      window.removeEventListener('resize', resizeHandler);
    };
  }, []);

  useEffect(() => {
    if (open) resizeHandler();
  }, [open]);

  const treeTotalData = useMemo(() => {
    const treeData = generateGroupTree({
      rawMenuData: JSON.parse(JSON.stringify(restaurantList)),
      locale,
      selGroup: true,
    });
    return JSON.parse(JSON.stringify(treeData));
  }, [restaurantList, locale]);

  const targetMenu = useMemo(() => {
    const convertNodes = (nodes) => {
      return nodes.map((item) => {
        return {
          key: item.key,
          id: item.id,
          parentId: item.parentId,
          pathIds: item.pathIds,
          name: item.name,
          disabled: false,
          foreign_name: item.foreign_name,
          title: item.title,
          isLeaf: true,
          isGroup: true,
        };
      });
    };
    if (!searchValue) {
      return convertNodes(treeTotalData.navMenu);
    }

    const target = searchValue.toLowerCase();
    const nodes = treeTotalData.allNodes.filter((item) => {
      const name = item?.name?.toLowerCase();
      const fName = item?.foreign_name?.toLowerCase();
      return name.includes(target) || fName.includes(target);
    });

    let targetNodes = [];
    if (nodes.length > 0) {
      const rootIds = nodes.map((node) => {
        const pathIds = node.pathIds.split('/');
        return pathIds.find((d) => !!d) || '';
      });

      targetNodes = treeTotalData.navMenu.filter((item) => rootIds.includes(`${item.id}_g`)) || [];
    }

    return convertNodes(targetNodes);
  }, [treeTotalData, searchValue]);

  const selectKey = useMemo(() => {
    if (!currentRgId) {
      return '';
    }
    const rootNode = treeTotalData.navMenu.find((item) => `${item.id}` === `${currentRgId}`);
    return rootNode?.key || '';
  }, [treeTotalData, currentRgId]);

  const handleSwitchGroup = (selectedKeys) => {
    if (!selectedKeys.length) return;
    setOpen(false);
    const curKey = selectedKeys[0];
    const node = treeTotalData.navMenu.find((item) => item.key == curKey);
    const restGroupId = `${node.id}`;
    dispatch(setCurrentRgId(restGroupId));
    dispatch(setCurrentPermissions({ currentRgId: restGroupId }));
  };

  const handleClickOutside = () => {
    setOpen(false);
  };

  const handleOpenPanel = () => {
    setOpen(true);
  };

  const handleSearchChanged = (e) => {
    if (e.type === 'compositionstart') return;
    setSearchValue(e.target.value);
  };

  const titleRender = (nodeData) => (
    <span>
      <span>{!nodeData.isGroup ? <BankOutlined /> : <ClusterOutlined />}</span>
      <span style={{ marginLeft: 4 }}>
        {getItemName(nodeData, locale)}({nodeData.id})
      </span>
    </span>
  );

  if (isLoading) return null;

  if (!isLoadSuccess)
    return (
      <div className={styles.restaurantSwitcher}>
        <Spin size="small" style={{ margin: '0 8px' }} />
      </div>
    );

  return (
    <ClickOutsideHandlerWrapper onClickOutside={handleClickOutside} inline={true}>
      <div className={styles.restaurantSwitcher} onClick={(e) => e.stopPropagation()}>
        {!!currentGroupInfo?.id && (
          <Button type="text" onClick={handleOpenPanel}>
            <span>
              {getItemName(currentGroupInfo, locale)}({currentGroupInfo.id})
            </span>
            <DownOutlined />
          </Button>
        )}

        <div className={`restaurant-panel ${open ? 'visible' : 'hidden'}`} ref={ref} style={panelPosition}>
          <Search
            allowClear
            value={searchValue}
            placeholder={formatMessage({ id: 'component.restaurant.filter' })}
            onChange={handleSearchChanged}
            onCompositionStart={handleSearchChanged}
            onCompositionEnd={handleSearchChanged}
          />
          {targetMenu.length > 0 ? (
            <div className="tree-container">
              <Tree
                treeData={targetMenu}
                selectedKeys={selectKey ? [selectKey] : []}
                onSelect={handleSwitchGroup}
                showLine={true}
                blockNode={true}
                switcherIcon={<span />}
                titleRender={titleRender}
              />
            </div>
          ) : (
            <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
          )}
        </div>
      </div>
    </ClickOutsideHandlerWrapper>
  );
};

export default GroupsDropdown;
