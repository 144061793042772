import React, { useEffect, useMemo, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { FormattedMessage, useIntl } from 'react-intl';
import { Button, Empty, Input, Tree } from 'antd';
import { BankOutlined, ClusterOutlined, DownOutlined } from '@ant-design/icons';
import debounce from 'lodash/debounce';
import { isSwitchingRestaurantSelector, localeSelector, restaurantListSelector } from '../../selector/app';
import { restauantProfileSelector } from '../../selector/setting';
import ClickOutsideHandlerWrapper from '../../hooks/useClickOutside';
import { switchRestaurant } from '../../actions/app';
import { getItemName } from '../../utils/utils';
import { generateRestaurantGroupTree } from '../../utils/tree';
import { ownedRestaurantsSelector } from 'src/selector/user';
import styles from './index.less';

const { Search } = Input;
const defaultWidth = 420;

function loopMatchPath({ treeNodes, matchedPaths, expandedKeys }) {
  return treeNodes.filter((treeNode) => {
    const matchedPath = treeNode.isLeaf ? treeNode.path : treeNode.path + '/';
    const isMatched = matchedPaths.some((path) => path.includes(matchedPath));

    if (isMatched) {
      if (!treeNode.isLeaf) expandedKeys.push(treeNode.key);

      if (treeNode.children) {
        treeNode.children = loopMatchPath({ treeNodes: treeNode.children, matchedPaths, expandedKeys });
      }
    }
    return isMatched;
  });
}

const RestaurantGroupDropdown = () => {
  const ref = useRef();
  const dispatch = useDispatch();
  const { formatMessage } = useIntl();
  const locale = useSelector(localeSelector);
  const { restaurantInfo } = useSelector(restauantProfileSelector);
  const isSwitching = useSelector(isSwitchingRestaurantSelector);
  const { ownedRestaurants } = useSelector(ownedRestaurantsSelector);
  const { isLoading, isLoadSuccess, restaurantList } = useSelector(restaurantListSelector);
  const [open, setOpen] = useState(false);
  const [searchValue, setSearchValue] = useState('');
  const [panelPosition, setPanelPosition] = useState(() => ({ left: 0, width: defaultWidth }));

  function handeResize() {
    const element = ref.current;
    if (!element) return;
    const box = element.getBoundingClientRect();
    if (!box) return;
    const { left, width } = box;
    const windowWidth = window.innerWidth;
    if (left + width < windowWidth) return;
    let _width = width;
    let _left = windowWidth - width;
    if (_left < 0) {
      _left = 0;
      _width = windowWidth;
    }
    setPanelPosition({ left: _left, width: _width });
  }

  const resizeHandler = debounce(handeResize, 250);

  useEffect(() => {
    window.addEventListener('resize', resizeHandler);

    return () => {
      window.removeEventListener('resize', resizeHandler);
    };
  }, []);

  useEffect(() => {
    if (open) resizeHandler();
  }, [open]);

  const rawTreeData = useMemo(() => {
    return generateRestaurantGroupTree({ rawMenuData: JSON.parse(JSON.stringify(restaurantList)), locale });
  }, [restaurantList, locale]);

  const ownedRestaurantTreeData = useMemo(() => {
    if (!ownedRestaurants.length) return { navMenu: [], restaurants: [] };
    const { navMenu, restaurants } = rawTreeData;
    let _ownedrestaurants = [];
    const matchedPaths = restaurants.reduce((acc, cur) => {
      if (ownedRestaurants.includes(cur.key)) {
        acc.push(cur.path);
        _ownedrestaurants.push(cur);
      }
      return acc;
    }, []);
    if (!matchedPaths.length) return { navMenu: [], restaurants: [] };
    const clonedNavMenu = JSON.parse(JSON.stringify(navMenu));
    const expandedKeys = [];
    const ownedRestaurantNavMenu = loopMatchPath({ treeNodes: clonedNavMenu, matchedPaths, expandedKeys });
    return { navMenu: ownedRestaurantNavMenu, restaurants: _ownedrestaurants, expandedKeys };
  }, [rawTreeData, ownedRestaurants]);

  const filteredMenus = useMemo(() => {
    const { navMenu, restaurants, expandedKeys } = ownedRestaurantTreeData;
    if (!searchValue) return { navMenu, expandedKeys };
    const matchedPaths = restaurants.reduce((acc, cur) => {
      if (cur.name.includes(searchValue) || cur.foreign_name?.includes(searchValue) || cur.key.includes(searchValue)) {
        acc.push(cur.path);
      }
      return acc;
    }, []);
    if (!matchedPaths.length) return { navMenu: [], expandedKeys: [] };
    const clonedNavMenu = JSON.parse(JSON.stringify(navMenu));
    const _expandedKeys = [];
    const _navMenu = loopMatchPath({ treeNodes: clonedNavMenu, matchedPaths, expandedKeys: _expandedKeys });
    return { navMenu: _navMenu, expandedKeys: _expandedKeys };
  }, [ownedRestaurantTreeData, searchValue]);

  const handleSwitchRestaurant = (selectedKeys) => {
    if (!selectedKeys.length) return;
    setOpen(false);
    dispatch(switchRestaurant(selectedKeys[0]));
  };

  const handleClickOutside = () => {
    setOpen(false);
  };

  const handleOpenPanel = () => {
    setOpen(true);
  };

  const handleSearchChanged = (e) => {
    if (e.type === 'compositionstart') return;
    setSearchValue(e.target.value);
  };

  const titleRender = (nodeData) => (
    <span>
      <span>{nodeData.isLeaf ? <BankOutlined /> : <ClusterOutlined />}</span>
      <span style={{ marginLeft: 4 }}>
        {getItemName(nodeData, locale)}({nodeData.key})
      </span>
    </span>
  );

  if (isLoading || isSwitching || !ownedRestaurants.length) return null;

  if (!isLoadSuccess)
    return (
      <span>
        <FormattedMessage id="component.loadRestaurantGroupFailed" />
        <a>
          <FormattedMessage id="action.retry" />
        </a>
      </span>
    );

  return (
    <ClickOutsideHandlerWrapper onClickOutside={handleClickOutside} inline={false}>
      <div className={styles.restaurantSwitcher} onClick={(e) => e.stopPropagation()}>
        {!!restaurantInfo.id && (
          <Button type="text" onClick={handleOpenPanel}>
            <span>
              {getItemName(restaurantInfo, locale)}({restaurantInfo.id})
            </span>
            <DownOutlined />
          </Button>
        )}

        <div className={`restaurant-panel ${open ? 'visible' : 'hidden'}`} ref={ref} style={panelPosition}>
          <Search
            allowClear
            value={searchValue}
            placeholder={formatMessage({ id: 'component.restaurant.filter' })}
            onChange={handleSearchChanged}
            onCompositionStart={handleSearchChanged}
            onCompositionEnd={handleSearchChanged}
          />
          {filteredMenus.navMenu.length > 0 ? (
            <div className="tree-container">
              <Tree
                treeData={filteredMenus.navMenu}
                selectedKeys={restaurantInfo.id ? [restaurantInfo.id] : []}
                onSelect={handleSwitchRestaurant}
                expandedKeys={filteredMenus.expandedKeys}
                showLine={true}
                blockNode={true}
                switcherIcon={<span />}
                titleRender={titleRender}
              />
            </div>
          ) : (
            <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
          )}
        </div>
      </div>
    </ClickOutsideHandlerWrapper>
  );
};

export default RestaurantGroupDropdown;
