import { createSelector } from 'reselect';
import { EXIST_FLAG } from 'src/consts/rgflags';

export const currentUserSelector = createSelector(
  (state) => state.rgUser.currentRgId,
  (currentRgId) => ({ currentRgId })
);

export const currentGroupInfoSelector = createSelector(
  (state) => state.app.restaurantList,
  (state) => state.rgUser.currentRgId,
  (state) => state.rgUser.owningRestaurants,
  (state) => state.user.ownedRestaurants,
  (restaurantList, currentRgId, owningRestaurants, ownedRestaurants) => {
    const currentGroupInfo = restaurantList.find((item) => `${item.id}` === `${currentRgId}`);

    if (!currentGroupInfo || owningRestaurants === EXIST_FLAG.checking) {
      return { currentGroupInfo: {}, rootGroupRestaurants: [], existRestaurants: EXIST_FLAG.checking };
    }

    const rests = [];
    let existRestaurants = EXIST_FLAG.notExist;
    const getRests = (node, rests) => {
      const hasGroup = node.children && node.children.length > 0;
      const hasRest = node.restaurants && node.restaurants.length > 0;
      if (hasGroup) {
        node.children.forEach((child) => {
          getRests(child, rests);
        });
      }
      if (hasRest) {
        rests.push(...node.restaurants);
      }
    };

    getRests(currentGroupInfo, rests);

    existRestaurants = !rests.length ? EXIST_FLAG.notExist : EXIST_FLAG.exist;
    return { currentGroupInfo, rootGroupRestaurants: rests, existRestaurants, ownedRestaurants };
  }
);

export const rgPermissionsSelector = createSelector(
  (state) => state.rgUser.rgPermissions,
  (state) => state.rgUser.currentRgId,
  (rgPermissions, currentRgId) => {
    if (!currentRgId || !rgPermissions) {
      return { curPermissions: null };
    }
    return { curPermissions: rgPermissions.get(currentRgId) || [] };
  }
);
