import React from 'react';
import { useSelector } from 'react-redux';
import Link from 'next/link';
import classNames from 'classnames';
import { MenuFoldOutlined, MenuUnfoldOutlined } from '@ant-design/icons';
import RestaurantGroupDropdown from './RestaurantGroupDropdown';
import GroupsDropdown from './GroupsDropdown';
import RightContent from './RightContent';
import { isGroupUserSelector, localeSelector } from 'src/selector/app';
import styles from './index.less';

const SmallLogo = `${process.env.ASSETS_PREFIX}/assets/logo_sm.png`;
const Logo = `${process.env.ASSETS_PREFIX}/assets/logo.png`;

/**
 * 管理页面通用头部
 * @param {Object} props
 * @param {Boolean} props.isMobile 是否mobile
 * @param {Number} props.sideBarWidth 左侧导航栏宽度 默认值为80
 * @param {Boolean} props.collapsed 左侧导航栏是否折叠
 * @param {Boolean} props.showNavToggle 是否显示左侧导航栏折叠按钮 默认为true
 * @param {Function} props.onCollapse 折叠左侧导航栏回调
 * @returns
 */
const GlobalHeader = (props) => {
  const { isMobile, sideBarWidth, collapsed, showNavToggle = true, onCollapse } = props;
  const _locale_ = useSelector(localeSelector);
  const isGroup = useSelector(isGroupUserSelector);

  return (
    <div
      className={classNames(styles.header, styles.fixHeader)}
      style={{ left: !showNavToggle ? 0 : collapsed ? (isMobile ? 0 : 80) : sideBarWidth }}
    >
      <div className={styles.left}>
        {isMobile && (
          <Link href="/" key="logo" locale={_locale_}>
            <a>
              <span className={styles.logo}>
                <img src={SmallLogo} alt="logo" />
              </span>
            </a>
          </Link>
        )}
        {showNavToggle ? (
          <span className={styles.trigger} onClick={onCollapse}>
            {collapsed ? <MenuUnfoldOutlined /> : <MenuFoldOutlined />}
          </span>
        ) : (
          <div className={styles.restaurantLogo}>
            <Link href="/" key="logo" locale={_locale_}>
              <a>
                <img src={Logo} width="178" />
              </a>
            </Link>
          </div>
        )}
        {isGroup ? <GroupsDropdown /> : <RestaurantGroupDropdown />}
      </div>
      <RightContent />
    </div>
  );
};

export default GlobalHeader;
